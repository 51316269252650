import { Button, Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

export default function RSVPStatus() {
  let params = useParams();

const confirmRSVPStatus = () => {
    console.log(params.eventId)
}


  return (
    <div className="sign-in-container">
      <div className="sign-in-box">
        <div className="stacked_row">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                form='name-form'
                type='submit'
                onClick={confirmRSVPStatus}
                variant="outlined"
                disableElevation
                size="large"
                fullWidth
              >
                CONFIRM ATTENDING
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                type="submit"
                disableElevation
                size="large"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                MAYBE
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                type="submit"
                disableElevation
                size="large"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                CAN'T GO
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
