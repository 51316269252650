import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Hidden,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ArrowBackIosRounded, ExpandMoreRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import FadeIn from "react-fade-in";
import { useNavigate } from "react-router-dom";
import {
  Link,
  Password,
  FingerprintSimple,
  Image,
  Export,
  Notification,
  Pen,
  Eye,
  X,
  Smiley,
} from "phosphor-react";
import ImageUpload from "../components/upload/ImageUpload";
import { useForm } from "react-hook-form";
import { get } from "lodash";

export default function EventDetails() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const ui = useSelector((state) => state.ui);
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      coverType: "",
      description: "",
      start: "",
      end: "",
      location: "",
    },
  });

  const onSubmit = (data) => console.log(data);

  const goBack = () => {
    dispatch({ type: "SET_NEW_EVENT_STEP", payload: 1 });
    navigate(-1);
  };

  const publish = () => {
    dispatch({ type: "SET_NEW_EVENT_STEP", payload: 3 });
    navigate("/create/share");
  };

  const changeType = (type) => {
    dispatch({ type: "SET_NEW_EVENT_PRIVACY", payload: type.target.value });
  };

  // Settings Accordion State
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Cover picker component
  const [coverType, setCoverType] = useState("");
  const chooseCoverType = (type) => {
    setCoverType(type);
    setValue("coverType", type);
    trigger("coverType");
  };

  const shrinkCoverButton = (box) => {
    if (coverType === "") {
      return "eventImage_button eventImage_button_none_selected";
    } else if (coverType === box) {
      return "eventImage_button eventImage_button_selected";
    } else {
      return "eventImage_button eventImage_button_hidden";
    }
  };

  const updateEvent = (update) => {
    console.log(update.target.value);
    console.log(update.target.name);
  };

  return (
    <form id="name-form" onSubmit={handleSubmit(onSubmit)}>
      <FadeIn>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">Create an Event</Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="clickableListTitle">
                  Event Details
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <div className="EventDetails_EventImage_container">
                <X size={32} weight="bold" className="close_button" />
                  <div
                    className={shrinkCoverButton("photo")}
                    onClick={() => chooseCoverType("photo")}
                  >
                    <div className="eventImage_button_content">
                      <Image size={32} weight="bold" />
                      <Typography>Image</Typography>
                      <ImageUpload />
                    </div>
                  </div>

                  <div
                    className={shrinkCoverButton("emoji")}
                    onClick={() => chooseCoverType("emoji")}
                  >
                    <div className="eventImage_button_content">
                      <Smiley size={32} weight="bold" />
                      <Typography>Emoji</Typography>
                      HELLO
                    </div>
                  </div>
                </div>
              </Grid>

              {coverType}
              <input
                name="coverType"
                // type="hidden"
                {...register("coverType", {
                  required: {
                    value: true,
                    message: "Select a cover type.",
                  },
                })}
              />

              {errors.coverType && "Select an event cover"}

              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Event Name"
                  fullWidth
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Event name is requried.",
                    },
                    minLength: {
                      value: 3,
                      message: "Choose a longer event name.",
                    },
                    maxLength: {
                      value: 60,
                      message: "Event name must be less than 60 characters.",
                    },
                  })}
                  error={errors.name ? true : false}
                  helperText={errors?.name?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="description"
                  onBlur={updateEvent}
                  label="Description"
                  multiline
                  minRows={2}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Time" fullWidth />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Date" fullWidth />
              </Grid>

              <Grid item xs={12}>
                <TextField label="Length" type="number" fullWidth />
              </Grid>

              <Grid item xs={12}>
                <TextField label="Location" fullWidth />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="clickableListTitle">
                  Event Privacy
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Select
                  onChange={changeType}
                  value={ui.newEvent.privacy}
                  fullWidth
                >
                  <MenuItem value={"url"}>
                    <Link weight="bold" className="small_list_icon" />
                    Basic URL
                  </MenuItem>
                  <MenuItem value={"password"}>
                    <Password className="small_list_icon" />
                    Password Protected
                  </MenuItem>
                  <MenuItem value={"invite"}>
                    <FingerprintSimple className="small_list_icon" />
                    Invite Only
                  </MenuItem>
                </Select>
              </Grid>

              {ui.newEvent.privacy === "password" && (
                <Grid item xs={12}>
                  <TextField
                    helperText="Choose any password you want. Learn more."
                    label="Password"
                    fullWidth
                  />
                </Grid>
              )}

              {/* <Grid item xs={12}>
              <Typography variant="caption">Invite guests and create unique URLs once you've published your event</Typography>
            </Grid> */}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disableElevation
                  size="large"
                  // onClick={publish}
                >
                  Publish &#38;{" "}
                  {ui.newEvent.privacy === "invite" ? "select guests" : "share"}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="clickableListTitle">
                  Advanced Settings
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Accordion
                  expanded={expanded === "sharing"}
                  onChange={handleChange("sharing")}
                  disableGutters
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls="sharingbh-content"
                    id="sharingbh-header"
                  >
                    <Export weight="bold" className="small_list_icon" />
                    <Typography>Sharing</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="URL"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                imvyt.com/
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch defaultChecked />}
                          label="Guests can invite friends"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  disableGutters
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Eye weight="bold" className="small_list_icon" />
                    <Typography>Display</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch defaultChecked />}
                          label="Show attendee count"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch defaultChecked />}
                          label="Show attendee names"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch defaultChecked />}
                          label="Show commments"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                  disableGutters
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Notification weight="bold" className="small_list_icon" />
                    <Typography>Notifications</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch defaultChecked />}
                          label="Send event reminder"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch defaultChecked />}
                          label="Send change notifications"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch defaultChecked />}
                          label="Send admin comments as notifications"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FadeIn>
    </form>
  );
}
