import React from "react";
import { useSelector } from "react-redux";
import LoginSetup from "../authentication/LoginSetup";
import { get } from "lodash";
import RSVPStatus from "./RSVPStatus";
import PhoneStep from "../authentication/steps/PhoneStep";
import RSVPNameStep from "./RSVPNameStep";
import { Button } from "@mui/material";

export default function RSVPflow() {
  const user = useSelector((state) => state.user);
  let authenticated = get(user, "authenticated");
  let credentials = get(user, "credentials");

  let content

  if (authenticated === false) {
    content = <PhoneStep />
  } else if (credentials.setup_status < 1) {
    content = (
      <>
      <RSVPNameStep />
      <div className="stacked_row"><Button fullWidth size="large" form="name-form" type="submit" variant="contained" disableElevation>Next</Button></div>
      </>
    )
  } else {
    content = <RSVPStatus />
  }

  return (
    <>
    {content}
    </>
  );
}
