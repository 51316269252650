import { Button } from '@mui/material'
import React from 'react'
import {useDispatch} from 'react-redux'
import { Link } from 'react-router-dom'


export default function Home() {

    const dispatch = useDispatch()

    return (
        <div>
Running app in: {process.env.NODE_ENV} <br /> 
            <Button variant="contained">Make Request</Button>
            <Link to="/random">Events Page</Link>
        </div>
    )
}
