import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getStorage, ref, uploadBytes } from "firebase/storage";

export default function ImageUpload() {

  const uploadFromBlobAsync = async ({ blobUrl, name }) => {
    if (!blobUrl || !name) return null;

    const storage = getStorage();
    const storageRef = ref(storage, `profile/M37ISn2TrmcU7DQWQfoc6hoCnXf2/${name}`);

    try {
      const blob = await fetch(blobUrl).then((r) => r.blob());

      const snapshot = await uploadBytes(storageRef, blob).then((snapshot) => {
        console.log(snapshot);
      });

      return 
    } catch (error) {
      throw error;
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setMessage(null);

    try {
      await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `${file.name}_${Date.now()}`,
      });
    } catch (e) {
      setIsLoading(false);
      setError(e);
      return;
    }

    setIsLoading(false);
    setMessage("File was uploaded 👍");
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isLoading
          ? "loading"
          : isDragActive
          ? "Drop the files here..."
          : "Drag 'n' drop some files here, or click to select files"}
      </div>
      {JSON.stringify(error || message)}
    </>
  );
}
